import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import ScrollToTarget from './lib/ScrollToTarget';
import ScrollReveal from './lib/ScrollReveal';
import PageTransition from './lib/PageTransition';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();
    ScrollToTarget.init();
    ScrollReveal.init();
    PageTransition.init();
};

require('doc-ready')(init);
