import serialize from 'form-serialize';
import $ from '../core/Dom';
import agent from '../core/request';

export default el => {
    const dom = $(el);
    const form = dom.find('form');
    const successDialog = dom.find('[data-success]');
    const errorDialog = dom.find('[data-error]');
    let request;
    let submitted = false;

    const endRequest = () => {
        if (!request) {
            return;
        }
        request = null;
    };

    const showReceipt = message => {
        errorDialog.removeClass('flex').addClass('hidden');
        successDialog.removeClass('hidden').addClass('flex').attr('role', 'alert').focus();
        form.find('input, button').attr('disabled', '');
        submitted = true;
    };

    const showErrors = message => {
        errorDialog.removeClass('hidden').addClass('flex').attr('role', 'alert').focus();
    };

    const postForm = () => {
        if (request || submitted) {
            return;
        }
        const data = serialize(form.get(0));
        request = agent
            .post('/')
            .accept('application/json')
            .send(data)
            .end((error, res) => {
                const body = JSON.parse(res.text);
                console.log(error, body);
                endRequest();
                if (error || !body.success) {
                    showErrors();
                } else {
                    form.find('input[type=email]').val('');
                    showReceipt();
                }
            });
    };

    const onSubmit = e => {
        e.preventDefault();
        postForm();
    };

    const init = () => {
        form.on('submit', onSubmit);
    };

    const destroy = () => {
        form.off('submit');
    };

    return {
        init,
        destroy
    };
};
