/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const LOCATION_OPEN = 'LOCATION_OPEN';
export const LOCATION_CLOSE = 'LOCATION_CLOSE';
export const LOCATION_HOVER = 'LOCATION_HOVER';
export const LOCATION_LEAVE = 'LOCATION_LEAVE';
export const TRANSITION_OUT = 'TRANSITION_OUT';

export const STREAM_ENTER_FULLSCREEN = 'STREAM_ENTER_FULLSCREEN';
export const STREAM_LEAVE_FULLSCREEN = 'STREAM_LEAVE_FULLSCREEN';
