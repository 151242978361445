import gsap from 'gsap';
import { Quart, Sine } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from './events';
import shouldAnimate from './ReducedMotion';

const isKeyCombo = e => e && (e.which === 2 || e.button === 4 || e.metaKey || e.shiftKey);

const handleClicks = e => {
    const target = e.triggerTarget;
    const href = target.getAttribute('href');
    if (!isKeyCombo(e) && href.indexOf('#') === -1 && href.indexOf(document.location.hostname) > -1 && target.getAttribute('target') !== '_blank' && !target.hasAttribute('download') && !target.hasAttribute('data-location') && !target.hasAttribute('data-list-back')) {
        e.preventDefault();
        Dispatch.emit(Events.TRANSITION_OUT, href);
    }
};

const transitionOut = (key, url) => {
    const items = $('main, footer').get();
    gsap.to(items, { duration: 0.5, opacity: 0, ease: Quart.easeInOut, onComplete: () => {
        document.location = url;
    }});
};

const init = () => {
    if (shouldAnimate()) {
        Dispatch.on(Events.TRANSITION_OUT, transitionOut);
        $('body').on('click', 'a', handleClicks);
        window.onpageshow = e => {
            if (e.persisted) {
                $('main, footer').attr('style', null);
            }
        };
    }
};

const destroy = () => {
    if (shouldAnimate()) {
        Dispatch.off(Events.TRANSITION_OUT, transitionOut);
        $('body').off('click', handleClicks);
    }
};

export default {
    init,
    destroy
};
