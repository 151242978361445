import Flickity from 'flickity';

export default el => {
    let flkty = null;

    const init = () => {
        flkty = new Flickity(el, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            wrapAround: false,
            freeScroll: true,
            freeScrollFriction: 0.045
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
    };

    const destroy = () => {
        flkty.destroy();
    };

    return {
        init,
        destroy
    };
};
