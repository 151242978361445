import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

const loadMapboxGl = require('bundle-loader?lazy&name=[name]!mapbox-gl');

export default (el, props) => {
    const dom = $(el);
    const mapNode = dom.find('[data-map]').get(0);
    const btnOpenMap = dom.find('[data-open-map]');
    const btnCloseMap = dom.find('[data-close-map]');

    let mapboxgl = null;
    let map = null;
    let markers = [];
    let selectedLatLng = null;

    const createMarkerElement = location => {
        const placeholder = document.createElement('div');
        placeholder.innerHTML = `<div id="marker_${location.slug}" class="map-marker" title="${location.title}"><div class="map-marker__circle">${props.icon}</div></div>`;
        return placeholder.firstElementChild;
    };

    const unselectMarkers = () => {
        if (map && !(map.isMoving() || map.isZooming())) {
            Dispatch.emit(Events.LOCATION_CLOSE);
        }
    };

    const fixAttributionLinks = () => {
        $('.mapboxgl-ctrl-attrib-inner a').each(link => {
            $(link).attr('rel', 'noopener nofollow');
        });
    };

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    const fitMap = () => {
        if (selectedLatLng) {
            map.setZoom(15);
            map.setCenter(selectedLatLng);
        } else if (markers.length > 1) {
            const bounds = new mapboxgl.LngLatBounds();
            markers.forEach(marker => {
                bounds.extend(marker.getLngLat());
            });
            map.fitBounds(bounds, {
                animate: false,
                padding: clamp(Viewport.width * 0.1, 90, 200)
            });
        } else {
            const marker = markers[0];
            map.setZoom(7);
            map.setCenter(marker.getLngLat());
        }
    };

    const getLocationBySlug = slug => props.locations.filter(loc => loc.slug === slug);

    const onLocationClose = key => {
        $(el).find('.map-marker').removeClass('map-marker--active');
    };

    const onLocationOpen = (key, slug) => {
        onLocationClose();
        const location = getLocationBySlug(slug);
        if (location) {
            const { marker, latlng } = location[0];
            marker.classList.add('map-marker--active');
            map.flyTo({
                center: latlng
            });
        }
    };

    const onLocationHover = (key, slug) => {
        const location = getLocationBySlug(slug);
        if (location) {
            const { marker } = location[0];
            marker.classList.add('map-marker--hover');
        }
    };

    const onLocationLeave = (key, slug) => {
        const location = getLocationBySlug(slug);
        if (location) {
            const { marker } = location[0];
            marker.classList.remove('map-marker--hover');
        }
    };

    const init = () => {
        btnOpenMap.on('click', () => {
            dom.addClass('map--open');
            requestAnimationFrame(() => {
                map.resize();
                fitMap();
            });
        });
        btnCloseMap.on('click', () => {
            dom.removeClass('map--open');
        });

        loadMapboxGl(def => {
            mapboxgl = def;
            mapboxgl.accessToken = props.mapboxToken;

            map = new mapboxgl.Map({
                container: mapNode,
                style: props.mapboxStyle,
                center: [14.829651, 68.780951],
                zoom: 9,
                scrollZoom: false
            });

            map.on('load', fixAttributionLinks);
            // map.on('mousedown', unselectMarkers);
            // map.on('touchstart', unselectMarkers);

            map.addControl(new mapboxgl.NavigationControl({
                showCompass: false,
                visualizePitch: false,
                showZoom: true
            }));

            markers = props.locations.map(location => {
                const marker = createMarkerElement(location);
                const latlng = [location.lng, location.lat];
                if (location.selected) {
                    selectedLatLng = latlng;
                    marker.classList.add('map-marker--active');
                }
                marker.addEventListener('click', () => {
                    Dispatch.emit(Events.LOCATION_OPEN, location.slug);
                });
                location.latlng = latlng;
                location.marker = marker;
                return new mapboxgl.Marker(marker).setLngLat(latlng).addTo(map);
            });

            Dispatch.on(Events.LOCATION_OPEN, onLocationOpen);
            Dispatch.on(Events.LOCATION_CLOSE, onLocationClose);
            Dispatch.on(Events.LOCATION_HOVER, onLocationHover);
            Dispatch.on(Events.LOCATION_LEAVE, onLocationLeave);

            fitMap();
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
