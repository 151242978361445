import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const body = $('body');
    const button = dom.find('[data-toggle]');
    const listHeading = dom.find('[data-list-heading]').get(0);
    const locationLinks = dom.find('[data-location]');
    const backToListButtons = dom.find('[data-list-back]');
    const fullscreenButtons = dom.find('[data-fullscreen]');
    let activeLiveStream = dom.find('iframe:not([src=""])');

    const isOpen = () => button.attr('aria-expanded') === 'true';

    const close = () => {
        Dispatch.emit(Events.LOCATION_CLOSE);
        button.attr('aria-expanded', 'false');
        button.get(0).focus();
        body.off('keyup', onKeyUp);
        body.off('click', onClick);
    };

    const open = () => {
        button.attr('aria-expanded', 'true');
        listHeading.focus();
        body.on('keyup', onKeyUp);
        body.on('click', onClick);
    };

    const toggle = () => {
        if (isOpen()) {
            close();
        } else {
            open();
        }
    };

    const onClick = e => {
        if (e && !el.parentElement.contains(e.target)) {
            close();
        }
    };

    const onKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            close();
        }
    };

    const onLocationOpen = (key, slug) => {
        if (!isOpen()) {
            open();
        }
        locationLinks.attr('aria-expanded', 'false');
        const locationLink = dom.find(`[data-location="${slug}"]`);
        if (locationLink) {
            locationLink.attr('aria-expanded', 'true');
            const liveStream = locationLink.parent().find('iframe');
            if (liveStream) {
                activeLiveStream = liveStream;
                liveStream.attr('src', liveStream.data('src'));
            }
        }
    };

    const onLocationClose = () => {
        if (activeLiveStream) {
            activeLiveStream.attr('src', null);
            activeLiveStream = null;
        }
        locationLinks.attr('aria-expanded', 'false');
        listHeading.focus();
    };

    const onEnterFullscreen = (key, data) => {
        if (activeLiveStream) {
            body.off('keyup', onKeyUp);
            body.off('click', onClick);
            activeLiveStream.attr('src', null);
        }
    };

    const onLeaveFullscreen = () => {
        if (activeLiveStream) {
            console.log('onLeaveFullscreen', activeLiveStream);
            activeLiveStream.attr('src', activeLiveStream.data('src'));
            setTimeout(() => {
                body.on('keyup', onKeyUp);
                body.on('click', onClick);
            }, 50);
        }
    };

    const init = () => {
        button.on('click', toggle);
        fullscreenButtons.on('click', e => {
            const target = $(e.triggerTarget);
            Dispatch.emit(Events.STREAM_ENTER_FULLSCREEN, {
                src: target.data('fullscreen'),
                live: target.data('live')
            });
        });
        locationLinks.on('click', e => {
            e.preventDefault();
            Dispatch.emit(Events.LOCATION_OPEN, $(e.triggerTarget).data('location'));
        });
        locationLinks.on('mouseenter', e => {
            e.preventDefault();
            Dispatch.emit(Events.LOCATION_HOVER, $(e.triggerTarget).data('location'));
        });
        locationLinks.on('mouseleave', e => {
            e.preventDefault();
            Dispatch.emit(Events.LOCATION_LEAVE, $(e.triggerTarget).data('location'));
        });
        backToListButtons.on('click', e => {
            e.preventDefault();
            Dispatch.emit(Events.LOCATION_CLOSE);
        });

        Dispatch.on(Events.LOCATION_OPEN, onLocationOpen);
        Dispatch.on(Events.LOCATION_CLOSE, onLocationClose);
        Dispatch.on(Events.STREAM_ENTER_FULLSCREEN, onEnterFullscreen);
        Dispatch.on(Events.STREAM_LEAVE_FULLSCREEN, onLeaveFullscreen);
    };

    const destroy = () => {
        button.off('click', toggle);
        fullscreenButtons.off('click');
        locationLinks.off('click');
        backToListButtons.off('click');
        Dispatch.off(Events.LOCATION_OPEN, onLocationOpen);
        Dispatch.off(Events.LOCATION_CLOSE, onLocationClose);
        Dispatch.off(Events.STREAM_ENTER_FULLSCREEN, onEnterFullscreen);
        Dispatch.off(Events.STREAM_LEAVE_FULLSCREEN, onLeaveFullscreen);
    };

    return {
        init,
        destroy
    };
};
