import gsap from 'gsap';
import { Circ, Cubic, Expo, Sine } from 'gsap/gsap-core';
import $ from '../core/Dom';
import shouldAnimate from './ReducedMotion';

const DEFAULT_DELAY = 0.1;

export default {
    init: () => {
        if (shouldAnimate()) {
            const callback = (entries, obs) => {
                let delay = 0;
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const addedDelayMs = parseFloat(entry.target.getAttribute('data-reveal'));
                        delay += Number.isNaN(addedDelayMs) ? 0 : addedDelayMs;
                        gsap.to(entry.target, {
                            delay, duration: 1.5, y: 0, ease: Expo.easeOut, clearProps: 'transform,opacity', onComplete: () => {
                                entry.target.setAttribute('data-revealed', true);
                            }
                        });
                        gsap.to(entry.target, {delay, duration: 0.5, opacity: 1, ease: Sine.easeInOut});
                        obs.unobserve(entry.target);
                        delay += DEFAULT_DELAY;
                    }
                });
            };

            const observer = new IntersectionObserver(callback, {
                rootMargin: '0px 0px -75px 0px',
                threshold: 0
            });

            $('[data-reveal], .wysiwyg--scrollreveal > *').each(node => {
                observer.observe(node);
            });
        }
    }
};
