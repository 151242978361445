import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const body = $('body');
    const button = dom.find('[data-close]');
    const iframe = dom.find('iframe');
    const liveStreamIcon = dom.find('[data-livestream-icon]');

    const onKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            Dispatch.emit(Events.STREAM_LEAVE_FULLSCREEN);
        }
    };

    const close = () => {
        iframe.attr('src', '');
        dom.attr('aria-hidden', 'true');
        dom.attr('hidden', '');
        dom.removeClass('flex');
        body.off('keyup', onKeyUp);
    };

    const open = (key, data) => {
        console.log(data);
        iframe.attr('src', data.src);
        liveStreamIcon.css('display', data.live ? 'inline-flex' : 'none');
        dom.attr('aria-hidden', 'false');
        dom.attr('hidden', null);
        dom.addClass('flex');
        body.on('keyup', onKeyUp);
    };

    const init = () => {
        button.on('click', e => {
            Dispatch.emit(Events.STREAM_LEAVE_FULLSCREEN);
        });

        Dispatch.on(Events.STREAM_ENTER_FULLSCREEN, open);
        Dispatch.on(Events.STREAM_LEAVE_FULLSCREEN, close);
    };

    const destroy = () => {
        button.off('click');
        Dispatch.off(Events.STREAM_ENTER_FULLSCREEN, open);
        Dispatch.off(Events.STREAM_LEAVE_FULLSCREEN, close);
    };

    return {
        init,
        destroy
    };
};
