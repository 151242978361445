import gsap from 'gsap';
import Headroom from 'headroom.js';
import { Quart, Sine, Quint, Circ } from 'gsap/gsap-core';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';
import shouldAnimate from '../lib/ReducedMotion';

export default el => {
    const dom = $(el);
    const menuTriggers = dom.find('[data-menu-toggle]');
    const menu = dom.find('[data-menu]').get(0);
    const menuItems = dom.find('[data-menu] li').get();
    const menuLinks = dom.find('[data-menu-item]');
    const menuImage = dom.find('[data-menu-image]');
    const menuImages = dom.find('[data-menu-image] img');
    const header = dom.find('[data-header]').get(0);
    const hamburger = dom.find('[data-hamburger]');
    const hamburgerTop = hamburger.find('path:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('path:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('path:nth-of-type(3)').get(0);
    const useAnimation = shouldAnimate();

    let menuOpen = false;
    let headroom = null;
    let menuLinksId = 0;

    menuItems.push(menuImage.get(0));

    const hamburgerToX = () => {
        const offset = 6;
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            y: offset * -1,
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 0 });
                gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
                gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: Quart.easeOut });
            }
        });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: Sine.easeIn });
        gsap.to(hamburgerBottom, {
            duration: 0.15,
            rotationZ: '0deg',
            transformOrigin: '50% 50%',
            ease: Sine.easeIn,
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: Quart.easeOut, clearProps: 'all' });
            }
        });
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            toggleMenu();
        }
    };

    const openMenu = () => {
        $('body').on('keyup', onBodyKeyUp).addClass('menu-open');
        if (useAnimation) {
            gsap.killTweensOf([menuItems, menu]);
            gsap.set(menuItems, {opacity: 0, x: 50});
            gsap.to(menu, {duration: 0.5, autoAlpha: 1, ease: Quint.easeInOut});
            gsap.to(menuItems, {delay: 0.3, duration: 0.3, stagger: 0.04, opacity: 1, ease: Sine.easeOut});
            gsap.to(menuItems, {delay: 0.3, duration: 0.7, stagger: 0.04, x: 0, ease: Circ.easeOut});
            headroom.freeze();
        } else {
            gsap.set(menu, { autoAlpha: 1 });
        }
        hamburgerToX();
    };

    const closeMenu = () => {
        $('body').off('keyup', onBodyKeyUp).removeClass('menu-open');
        if (useAnimation) {
            gsap.killTweensOf([menuItems, menu]);
            gsap.to(menuItems, {duration: 0.15, opacity: 0, ease: Sine.easeInOut});
            gsap.to(menu, {delay: 0.15, duration: 0.35, autoAlpha: 0, ease: Sine.easeInOut});
            headroom.unfreeze();
        } else {
            gsap.set(menu, { autoAlpha: 0 });
        }
        xToHamburger();
    };

    const toggleMenu = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
        menuOpen = !menuOpen;
    };

    const transitionOut = () => {
        closeMenu();
        headroom.unpin();
    };

    const init = () => {
        menuTriggers.on('click', e => {
            e.preventDefault();
            toggleMenu();
        });
        menuLinks.on('mouseenter', e => {
            clearTimeout(menuLinksId);
            menuImages.removeClass('z-20');
            $(`#menuImage_${e.target.getAttribute('data-menu-item')}`).addClass('z-20');
        });
        menuLinks.on('mouseleave', e => {
            menuLinksId = setTimeout(() => {
                menuImages.removeClass('z-20').last().addClass('z-20');
            }, 50);
        });
        if (useAnimation) {
            headroom = new Headroom(header);
            headroom.init();
            headroom.pin();
            $(header).on('focusin', () => {
                headroom.pin();
            });
            Dispatch.on(Events.TRANSITION_OUT, transitionOut);
        }
    };

    const destroy = () => {
        if (useAnimation) {
            if (headroom) {
                headroom.destroy();
            }
            $(header).off('focuswithin');
            Dispatch.off(Events.TRANSITION_OUT, transitionOut);
        }
        menuTriggers.off('click');
    };

    return {
        init,
        destroy
    };
};
